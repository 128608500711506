import api from "@/api";

const actions = {
  async setShowFilterActive({ commit }) {
    commit("SET_SHOW_FILTER_ACTIVE", state.showFilterActive);
  },
  async setUnassignedFilterActive({ commit }) {
    commit("SET_SHOW_UNASSIGNED_FILTER_ACTIVE", state.unassignedFilterActive);
  },
  async setUnsentResponsesFilterActive({ commit }) {
    commit("SET_SHOW_UNSENT_RESPONSES_FILTER_ACTIVE", state.unsentResponsesFilterActive);
  },
  async setSentResponsesFilterActive({ commit }) {
    commit("SET_SHOW_SENT_RESPONSES_FILTER_ACTIVE", state.sentResponsesFilterActive);
  },
  async assignsTeacher({ commit }, data) {
    await api
      .assignesTeacher(data)
      .then(response => {
        commit("RELOAD_UNASSIGNED_LIST", data);
        commit("ADD_RESULTS", response.data);
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },
  async createResponse({ commit }, response) {
    await api
      .createResponse(response)
      .then(response => {
        commit("ADD_TO_RESPONSES", response.data);
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },
  async deleteResponse({ commit }, response) {
    await api
      .deleteResponse(response)
      .then(response => {
        commit("REMOVE_FROM_RESPONSES", response.data);
        commit("ADD_RESULTS", response.data);
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },

  async hideResponse({ commit }, response) {
    response.sent = true;
    let currentResponse = response;
    await api
      .hideResponse(response)
      .then(response => {
        commit("RELOAD_UNSENT_RESPONSE", [currentResponse]);
        commit("ADD_RESULTS", response.data);
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },

  async loadResponses({ commit, dispatch }, responses) {
    await api
      .loadResponses(responses)
      .then(response => {
        commit("LOAD_RESPONSES", response.data);
        dispatch("changeIsLoadingState");
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },

  async loadResponsePagination({ commit, dispatch }, responses){
    dispatch("changeIsLoadingState");
    await api
    .loadResponses(responses)
    .then(response => {
      commit("LOAD_RESPONSES", response.data);
      dispatch("changeIsLoadingState");
    })
    .catch(error => {
      commit("ADD_ERRORS", error);
    });
  },

  async loadDropdownLevels({ commit }) {
    await api
      .loadDropdownLevels()
      .then(response => {
        commit("LOAD_LEVELS", response.data);
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },
  async initializeResponse({ commit }) {
    commit("INITIALIZE_RESULTS");
    commit("INITIALIZE_ERRORS");
    commit("INITIALIZE_RESPONSE");
  },
  async initializeUnassignedResponses({ commit, dispatch }) {
    await api
      .loadUnassingedResponses()
      .then(response => {
        commit("INITIALIZE_UNASIGNED_RESPONSE", response.data);
        dispatch("changeIsLoadingState");
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },
  async initializeNotGradedResponses({ commit }) {
    await api
      .loadNotGradedResponses()
      .then(response => {
        commit("INITIALIZE_NOT_GRADED_RESPONSE", response.data);
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },
  async initializeUnsentResponses({ commit }) {
    await api
      .loadUnsentResponses()
      .then(response => {
        commit("INITIALIZE_UNSENT_RESPONSE", response.data);
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },
  async initializeSentResponses({ commit }) {
    await api
      .loadSentResponses()
      .then(response => {
        commit("INITIALIZE_SENT_RESPONSE", response.data);
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },
  async sendsResponses({ commit, dispatch}, responses) {
    await api
      .sendsResponses(responses)
      .then(response => { 
        console.log(response);
        commit("RELOAD_UNSENT_RESPONSE", responses);
        commit("RELOAD_SENT_RESPONSE", responses);
        commit("ADD_RESULTS",response.data)
        dispatch("initializeSentResponses")
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },

  async updateResponse({ commit }, response) {
    await api
      .updateResponse(response)
      .then(response => {
        commit("UPDATE_RESPONSE", response.data);
        commit("ADD_RESULTS", response.data);
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },

  async updateResponseWhitoutGrade({ commit }, response) {
    await api
      .updateResponseWhitoutGrade(response)
      .then(response => {
        commit("UPDATE_RESPONSE_WHITOUTGRADE", response.data);
        commit("ADD_RESULTS", response.data);
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },
  async filterResponses({ commit }, filterParams) {
    await api.loadResponses(filterParams).then(response => {
      console.log('unsent',response)
      commit("SET_FILTERED_RESPONSES", response.data);
    });
  },
  async filterUnassignedResponses({ commit }, filterParams) {
    await api.loadUnassingedResponses(filterParams).then(response => {
      console.log('unsent',response)
      commit("SET_UNASSIGNED_FILTERED_RESPONSES", response.data);
    });
  },
  async filterUnsentResponses({ commit }, filterParams) {
    await api.loadUnsentResponses(filterParams).then(response => {
      console.log('unsent',response)
    
      commit("SET_UNSENT_FILTERED_RESPONSES",response.data, filterParams);
    });
  },
  async filterSentResponses({ commit }, filterParams) {
    await api.loadSentResponses(filterParams).then(response => {
      console.log('sent',response)
    
      commit("SET_SENT_FILTERED_RESPONSES",response.data, filterParams);
    });
  },

  setEditedResponse({ commit, dispatch }, selectedResponse) {
    commit("SET_EDITED_RESPONSE", selectedResponse);
    dispatch("loadTree", selectedResponse);
  },

  setElemenToDelete({ commit }, selectedResponse) {
    commit("SET_EDITED_RESPONSE", selectedResponse);
  },
  loadTreeAfterDelete({dispatch}){
    dispatch("loadTree", state.editedResponse);
  }
};

const getters = {
  getDefaultResponse: state => state.defaultResponse,
  getEditedResponse: state => state.editedResponse,
  getResponses: state => state.responses,
  getDropdownLevels: state => state.levels,
  getUnassignedResponses: state => state.unassignedResponses,
  getNotGradedResponses: state => state.notGradedResponses,
  getUnsentResponses: state => state.unsentResponses,
  getSentResponses: state => state.sentResponses,
  getshowFilterActive: state => state.showFilterActive,
  getUnassignedFilterActive: state => state.unassignedFilterActive,
  getUnsentResponsesFilterActive: state => state.unsentResponsesFilterActive,
  getSentResponsesFilterActive: state => state.sentResponsesFilterActive,
  getTotalResponsesPagination: state => state.totalResponses
};

const mutations = {
  LOAD_RESPONSES: (state, responses) => {
    state.totalResponses = responses.total;
    state.responses = [];
    for (var i = 0; i < responses.responses.length; i++) {
      state.responses.push(responses.responses[i]);
    }
  },
  LOAD_LEVELS: (state, responses) => {
    for (var i = 0; i < responses.length; i++) {
      state.levels.push(responses[i]);
    }
  },
  ADD_TO_RESPONSES: (state, response) => {
    state.responses.push(response);
  },
  INITIALIZE_RESPONSE: state => {
    state.responses = [];
  },
  INITIALIZE_UNASIGNED_RESPONSE: (state, unassignedResponses) => {
    state.unassignedResponses = [];
    for (var i = 0; i < unassignedResponses.length; i++) {
      state.unassignedResponses.push(unassignedResponses[i]);
    }
  },

  INITIALIZE_UNSENT_RESPONSE: (state, unsentResponses) => {
    state.unsentResponses = [];
    for (var i = 0; i < unsentResponses.length; i++) {
      state.unsentResponses.push(unsentResponses[i]);
    }
  },
  INITIALIZE_SENT_RESPONSE: (state, sentResponses) => {
    state.sentResponses = [];
    for (var i = 0; i < sentResponses.length; i++) {
      state.sentResponses.push(sentResponses[i]);
    }
  },

  INITIALIZE_NOT_GRADED_RESPONSE: (state, notGradedResponses) => {
    state.notGradedResponses = [];
    for (var i = 0; i < notGradedResponses.length; i++) {
      state.notGradedResponses.push(notGradedResponses[i]);
    }
  },
  RELOAD_UNASSIGNED_LIST: (state, data) => {
    for (var i = 0; i < data.responses.length; i++) {
      let responseIndex = state.unassignedResponses.findIndex(
        x => x.id === data.responses[i].id
      );
      state.unassignedResponses.splice(responseIndex, 1);
    }
  },

  RELOAD_UNSENT_RESPONSE: (state, responses) => {
    for (var i = 0; i < responses.length; i++) {
      let responseIndex = state.unsentResponses.findIndex(
        x => x.id === responses[i].id
      );
      state.unsentResponses.splice(responseIndex, 1);
    }
  },

  RELOAD_SENT_RESPONSE: (state, responses) => { 
      state.sentResponses.push(responses)
  },

  REMOVE_FROM_RESPONSES: (state, payload) => {
    let responseIndex = state.responses.findIndex(
      x => x.id === payload.response.id
    );
    state.responses.splice(responseIndex, 1);
  },
  SET_EDITED_RESPONSE: (state, selectedResponse) => {
    state.editedResponse.id = selectedResponse.id;
    state.editedResponse.student_id = selectedResponse.student_id;
    state.editedResponse.student = selectedResponse.student.name;
    state.editedResponse.language_id = selectedResponse.language_id;
    state.editedResponse.language = selectedResponse.language.name;
    state.editedResponse.graded = selectedResponse.graded;
    state.editedResponse.level = selectedResponse.level;
    state.editedResponse.general_level = selectedResponse.general_level;
    state.editedResponse.teacher_id = selectedResponse.teacher_id;
    state.editedResponse.writing_level = selectedResponse.writing_level;
    state.editedResponse.email = selectedResponse.student.email;
    let company = selectedResponse.student.company;
    if (company === undefined) {
      state.editedResponse.company = "Not Company Found";
    } else {
      state.editedResponse.company = company.name;
    }
    if (state.editedResponse.teacher !== "") {
      state.editedResponse.teacher = selectedResponse.teacher.name;
    }
    state.editedResponse.comments = selectedResponse.comments;
    state.editedResponse.sent = selectedResponse.sent;
  },
  SET_FILTERED_RESPONSES: (state, responses) => {
    state.totalResponses = responses.total;
    state.responses = [];
    for (var i = 0; i < responses.total; i++) {
      state.responses.push(responses.responses[i]);
    }
  },

  SET_UNASSIGNED_FILTERED_RESPONSES: (state, responses) => {
    state.unassignedResponses = [];
    state.unassignedResponses = responses;
    // for (var i = 0; i < responses.length; i++) {
    //   state.unassignedResponses.push(responses[i]);
    // }
  },
  SET_UNSENT_FILTERED_RESPONSES: (state, responses) => {
    state.unsentResponses = [];
    state.unsentResponses = responses;
    // console.log(filterParams)
    // for ( let i = 0; i < responses.length ; i++){
    //   if(responses[i].language_id == filterParams.language_id && responses[i].company_id == filterParams.company_id){
    //     console.log('encontre lenguaje and id')
    //   }
    // }
    // console.log(responses)
  },
  SET_SENT_FILTERED_RESPONSES: (state, responses) => {
    state.sentResponses = [];
    state.sentResponses = responses;
    // console.log(filterParams)
    // for ( let i = 0; i < responses.length ; i++){
    //   if(responses[i].language_id == filterParams.language_id && responses[i].company_id == filterParams.company_id){
    //     console.log('encontre lenguaje and id')
    //   }
    // }
    // console.log(responses)
  },

  UPDATE_RESPONSE: (state, response) => {
    response = response.response;
    let responseIndex = state.responses.findIndex(x => x.id == response.id);
    state.responses.splice(responseIndex, 1, response);
  },
  UPDATE_RESPONSE_WHITOUTGRADE: (state, response) => {
    response = response.response;
    let responseIndex = state.responses.findIndex(x => x.id == response.id);
    state.responses.splice(responseIndex, 1, response);
  },

  SET_SHOW_FILTER_ACTIVE: (state, status) => {
    state.showFilterActive = !status;
  },

  SET_SHOW_UNASSIGNED_FILTER_ACTIVE: (state, status) => {
    state.unassignedFilterActive = !status;
  },

  SET_SHOW_UNSENT_RESPONSES_FILTER_ACTIVE:(state, status) =>{
    state.unsentResponsesFilterActive = !status
  },
  SET_SHOW_SENT_RESPONSES_FILTER_ACTIVE:(state, status) =>{
    state.sentResponsesFilterActive = !status
  }
};

const state = {
  defaultResponse: {
    id: "",
    student_id: "",
    student: "",
    language_id: "",
    language: "",
    graded: "",
    level: "",
    general_level: "",
    teacher_id: "",
    teacher: "",
    comments: "",
    sent: "",
    company: "",
    writing_level:""
  },
  editedResponse: {
    id: "",
    student_id: "",
    student: "",
    language_id: "",
    language: "",
    graded: "",
    level: "",
    general_level: "",
    teacher_id: "",
    teacher: "",
    comments: "",
    sent: "",
    company: "",
    writing_level:""
  },
  levels: [],
  responses: [],
  showFilterActive: false,
  unassignedResponses: [],
  notGradedResponses: [],
  unsentResponses: [],
  sentResponses: [],
  unassignedFilterActive: false,
  unsentResponsesFilterActive:false,
  sentResponsesFilterActive:false,
  totalResponses : 0 
};

export default {
  actions,
  getters,
  mutations,
  state
};
