import axios from "axios";

// const API_URL = process.env.API_URL || "http://localhost:3000";
const API_URL = process.env.VUE_APP_DEXTER_BACK_ORIGIN;
// `baseURL` is prepended to URLs passed to axios
axios.defaults.baseURL = API_URL;
axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.csrf;
axios.defaults.headers.common["Access-Control-Allow-Origin"] =
 "https://www.bbediscover.com";
axios.defaults.headers.common["Access-Control-Allow-Methods"] =
 "OPTIONS,POST,GET";
//"Access-Control-Allow-Origin": "https://bbediscover.com",
// POST with JSON content type// "Access-Control-Allow-Methods": "OPTIONS,POST,GET"
// POST with JSON content type
axios.defaults.headers.post["Content-Type"] = "application/json";

// `xsrfCookieName` is the name of the cookie
// to use as a value for xsrf token.
// Default is 'XSRF-TOKEN'
axios.defaults.xsrfCookieName = "CSRF-TOKEN";

// `xsrfHeaderName` is the name of the http header
// that carries the xsrf token value.
// Default is 'X-XSRF-TOKEN'
axios.defaults.xsrfHeaderName = "X-CSRF-Token";

// `withCredentials` indicates whether or not cross-site
// Access-Control requests should be made using credentials.
axios.defaults.withCredentials = false;

export default {
  // for getting levels
  loadDropdownLevels() {
    return axios.get("/students/student_levels/");
  },
  // for getting the responses
  getAudios(responseId) {
    return axios.get(`/responses/${responseId}/audios`);
  },
  // for sendsAnswerToServer
  createAnswer(formData) {
    return axios.post("/answers", formData);
  },
  // for sessions
  signIn(currentUser) {
    return axios.post("/sign_in", { sign_in: currentUser });
  },
  signUp(student) {
    return axios.post("/students/create_guest_student", { student: student });
  },
  // for company actions
  createCompany(company) {
    return axios.post("/companies", company);
  },
  deleteCompany(company) {
    return axios.delete(`/companies/${company.id}`, company);
  },
  loadCompanies() {
    return axios.get("/companies");
  },
  loadCompaniesDropdown() {
    return axios.get("/companies");
  },
  updateCompany(company) {
    return axios.patch(`/companies/${company.id}`, company);
  },
  // for responses actions
  assignesTeacher(data) {
    return axios.post("/responses/assigns_teacher_to_responses", {
      response: data,
    });
  },
  loadResponses(filterParams) {
    
    return axios.get(`/responses`, { params: { filter_params: filterParams }});
  },
  loadUnassingedResponses(filterParams) {
    return axios.get("responses/list_unassigned_teacher_response", {
      params: { filter_params: filterParams },
    });
  },
  loadNotGradedResponses() {
    return axios.get("responses/list_ungraded_responses");
  },
  loadUnsentResponses(filterParams) { 
    return axios.get("responses/list_unsent_reports", {
      params: { filter_params: filterParams },
    });
  },
  loadSentResponses(filterParams) {
    return axios.get("responses/list_sent_reports", {
      params: { filter_params: filterParams },
    });
  },
  createResponse(response) {
    return axios.post("/responses", response);
  },
  sendsResponses(responses) {
    return axios.post("/responses/sends_selected_reports", {
      response: responses,
    });
  },
  hideResponse(response) {
    return axios.post(`/responses/${response.id}/hide`, { response: response });
  },
  deleteResponse(response) {
    return axios.delete(`/responses/${response.id}`, response);
  },
  generateResponse(languageId) {
    return axios.get(`responses/new/?language=${languageId}`);
  },
  updateResponse(response) {
    return axios.post(`/responses/${response.id}/grade`, {
      response: response,
    });
  },
  updateResponseWhitoutGrade(response) {
    return axios.patch(`/responses/${response.id}`, {
      response: response,
    });
  },
  // for languages actions
  createLanguage(language) {
    return axios.post("/languages", language);
  },
  deleteLanguage(language) {
    return axios.delete(`/languages/${language.id}`, language);
  },
  loadLanguages() {
    return axios.get("/languages");
  },
  loadLanguagesDropdown() {
    return axios.get("/languages");
  },
  loadLanguagesDropdownGuest() {
    return axios.get("languages/index_guest_user");
  },
  updateLanguage(language) {
    return axios.patch(`/languages/${language.id}`, language);
  },
  // for questions actions
  loadQuestionLevelsDropdown() {
    return axios.get("/questions/question_levels");
  },
  createQuestion(question) {
    return axios.post("/questions", question);
  },
  deleteQuestion(question) {
    return axios.delete(`/questions/${question.id}`, question);
  },
  loadQuestions(filterParams) {
    return axios.get("/questions", { params: { filter_params: filterParams } });
  },
  loadQuestionsDropdown() {
    return axios.get("/questions");
  },
  updateQuestion(question) {
    return axios.patch(`/questions/${question.id}`, question);
  },
  // for teacher actions
  createTeacher(teacher) {
    return axios.post("/teachers", teacher);
  },
  loadTeachers() {
    return axios.get("/teachers");
  },
  updateTeacher(teacher) {
    return axios.patch(`/teachers/${teacher.id}`, teacher);
  },
  deleteTeacher(teacher) {
    return axios.delete(`/teachers/${teacher.id}`, teacher);
  },
  // for settings actions
  createSetting(setting) {
    return axios.post("/settings", setting);
  },
  deleteSetting(setting) {
    return axios.delete(`/settings/${setting.id}`, setting);
  },
  loadSettings() {
    return axios.get("/settings");
  },
  updateSetting(setting) {
    return axios.patch(`/settings/${setting.id}`, setting);
  },
  // for student actions
  createStudent(student) {
    return axios.post("/students", { student: student });
  },
  deleteStudent(student) {
    return axios.delete(`/students/${student.id}`, student);
  },
  loadStudents(filterParams) {
    return axios.get("/students", { params: { filter_params: filterParams } });
  },
  loadStudentLanguages() {
    return axios.get("/students/student_languages");
  },
  submitStudentCVS(formData) {
    return axios.post("/students/import", formData);
  },
  updateStudent(student) {
    return axios.patch(`/students/${student.id}`, { student: student });
  },
  // for enable test to students 
  enableTest(student){
    return axios.post(`/responses/send_selected_new_exams`, { params:student  });
  },
  // for question actions
  getQuestions() {
    return axios.get("/questions");
  },
  // for users
  createUser(user) {
    return axios.post("/users", { user: user });
  },
  deleteUser(user) {
    return axios.delete(`/users/${user.id}`, user);
  },
  loadUsers() {
    return axios.get("/users");
  },
  updateUser(user) {
    return axios.patch(`/users/${user.id}`, { user: user });
  },
  // for update name student
  updateStudentName(student) {
    return axios.post(`/students/update_student_name`, { student: student });
  },
  //For get name of student
  getNameStudent(email) {
    return axios.get("/students/name_student", { currentUser: email });
  },
  // for get Name company in questionary
  getNameStudentCompany(email) {
    return axios.get("/students/name_company", { currentUser: email });
  },

  //re send emails to students
  reSendEmail(id){
    return axios.post("/students/resend_email", { id: id }); 
  }
};

// Intercept all 401 Unauthorized responses before
// they are handled by `then` or `catch`

import store from "./store";
import router from "./router";

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (!error.response) {
      // network error
      alert(`Unable to access API: ${error.message}`);
    } else if (error.response.status == 401) {
      store.dispatch("signOutTime", error);
      // router.push('Home')
    } else if (error.response.status == 403) {
      router.push("Home");
    }
    return Promise.reject(error.response.data);
  }
);
