// import api from '@/api'
import {
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from "tiptap-vuetify";

const actions = {
  async changeIsLoadingState({ commit }) {
    commit("CHANGE_IS_LOADING_STATE", state.isLoading);
  },

  async updateFilterParams({ commit}, filter_params) {
    commit("UPDATE_FILTER_PARAMS", filter_params);
  }
};

const getters = {
  getErrors: state => state.errors,
  getExtentions: state => state.extensions,
  getResults: state => state.results,
  isInfoLoading: state => state.isLoading,
  getFilterParams: state => state.filterParams
};

const mutations = {
  CHANGE_IS_LOADING_STATE: (state, isLoading) => {
    state.isLoading = !isLoading;
  },
  SET_STUDENTS_CVS_RESPONSE: (state, response) => {
    state.results.push(response);
  },
  ADD_ERRORS: (state, payload) => {
    state.errors = [];
    state.errors.push(payload.error);
  },
  ADD_RESULTS: (state, payload) => {
    state.results = [];
    state.results.push(payload.message);
  },
  INITIALIZE_ERRORS: state => {
    state.errors = [];
  },
  INITIALIZE_RESULTS: state => {
    state.results = [];
  },
  UPDATE_FILTER_PARAMS: (state, filter_params) => {
    state.filterParams = {
      ...state.filterParams,
      ...filter_params
    };
  }
};

const state = {
  errors: [],
  extensions: [
    History,
    Blockquote,
    Link,
    Underline,
    Strike,
    Italic,
    ListItem,
    BulletList,
    OrderedList,
    [
      Heading,
      {
        options: {
          levels: [1, 2, 3]
        }
      }
    ],
    Bold,
    Code,
    HorizontalRule,
    Paragraph,
    HardBreak
  ],
  isLoading: false,
  results: [],
  filterParams: {
    company_id: "",
    graded_status: "",
    language_id: "",
    teacher_id: "",
    level:"",
    page: 1,
    per_page: 10
  }
};

export default {
  actions,
  getters,
  mutations,
  state
};
