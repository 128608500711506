<template>
  <div class="">
    <Loading v-if="isInfoLoading"/>
    <v-card v-else class="mx-3 my-5 pa-1">
      <Errors :errors="getErrors" />
      <Results :results="getResults" />

      <v-toolbar color="bbe-blue" dark >

        <v-toolbar-title>RESPONSES</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn icon @click="showFilter()">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-dialog v-model="getshowFilterActive"  max-width="900" height='600'>
          <v-card>
            <v-card-title class="headline bbe-blue justify-center white--text">
              Filter
            </v-card-title>
            <v-card-text>
              <ResponseFilter />
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-menu offset-y offset-yorigin="center center" v-if="!getIsUserTeacher">
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="item in items" :key="item.title" :to="item.path">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>

      <!-- this is for searching filter -->
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Quick search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <JsonExcel
        ref="jsonExcel"
        class="btn btn-default d-inline"
        :data="preparedData"
        :fields="json_fields"
        worksheet="Responses"
        name="Responses.xls"
        type="csv"
        style="display: none !important"
        > 
        </JsonExcel>
          <v-btn class="bbe-blue d-inline" dark text
           @click="prepareData"
           v-if="!getIsUserTeacher">
            Download CSV
          </v-btn>
      <v-card class="mx-1 my-1 pa-3">
        <v-data-table
        :headers="headers"
        :items="responses"
        :items-per-page="itemsPerPage"
        sort-by="name"
        :search="search"
        class="pa-1 ma-1 elevation-1"
        hide-default-footer
        
        >
          <!-- The following are the dialogs for the CRUD actions -->
          <template v-slot:top>
           
            <!-- The following dialog is for edit item -->
            <v-dialog v-model="dialogEdit" id="dialogEdit" persistent>
              <v-card>

                <v-card-title class="headline justify-center bbe-blue white--text" >
                  <span class="headline">Response - {{ formTitle }}</span>
                </v-card-title>

                <v-card class="ma-2">
                  <v-row class="pa-5 ma-1">
                    <v-col sm="6"  id="algo">
                      <Treeview />
                    </v-col>

                    <v-col sm="6">
                      <v-card-title>
                        <v-row>
                          <v-col sm="4">
                            Level
                          </v-col>
                          <v-col sm="8">
                            {{ getQuestionSelectedLevel }}
                          </v-col>
                        </v-row>
                      </v-card-title>
                      <v-card-text>
                        <div v-html='getQuestionSelectedTitle'/>
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-card>

                <Wavesurfer />

                <v-main class="pt-1">
                  <v-card-text>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-row>
                          <v-col class="font-weight-bold"  style="width: 100px !important; flex-grow: 0;">
                            Student
                          </v-col>

                          <v-col class="custom-scroll" style="min-width: 200px; flex-grow: 1;" >
                            {{ getEditedResponse.student }}
                          </v-col>

                          <v-col class="font-weight-bold"  style="width: 100px; flex-grow: 0;" v-if="getIsUserAdmin" >
                            Email
                          </v-col>

                          <v-col  class="custom-scroll" style="min-width: 200px; overflow-x: scroll; white-space: nowrap; flex-grow: 1;" v-if="getIsUserAdmin">
                            {{ getEditedResponse.email }}
                          </v-col>

                          <v-col style="width: 100px; flex-grow: 0" class="font-weight-bold justify-center">
                            Language
                          </v-col>
                          <v-col style="flex-grow: 0;" >
                            {{ getEditedResponse.language }}
                          </v-col>


                          <v-col class="font-weight-bold justify-center"  style="width: 100px; flex-grow: 0;">
                            Company
                          </v-col>
                          <v-col style="flex-grow: 1;" >
                            {{ getEditedResponse.company }}
                          </v-col>
                        </v-row>


                        <v-row>
                          <v-col sm="2" class="font-weight-bold">
                            Speaking Exam Level
                          </v-col>
                          <v-col sm="4">
                            <v-select
                            v-model="getEditedResponse.level"
                            :items="getDropdownLevels"
                            item-text="name"
                            item-value="id"
                            :menu-props="{ maxHeight: '400' }"
                            label="Select Level"
                            chips
                            solo
                            ></v-select>
                          </v-col>
                          <v-col sm="2" class="font-weight-bold">
                            Written Exam Level
                          </v-col>
                          <v-col sm="4">
                            <v-select
                            v-model="getEditedResponse.writing_level"
                            :items="getDropdownLevels"
                            item-text="name"
                            item-value="id"
                            :menu-props="{ maxHeight: '400' }"
                            label="Select Level"
                            chips
                            solo
                            ></v-select>
                          </v-col>

                          <v-col sm="2" class="font-weight-bold">
                            General Level
                          </v-col>
                          <v-col sm="4">
                            <v-select
                            v-model="getEditedResponse.general_level"
                            :items="getDropdownLevels"
                            item-text="name"
                            item-value="id"
                            :menu-props="{ maxHeight: '400' }"
                            label="Select General Level"
                            chips
                            solo
                            ></v-select>
                          </v-col>
                        </v-row>

                        <v-row v-if="!getIsUserTeacher">
                          <v-col sm="2" class="font-weight-bold">
                            Teacher
                          </v-col>
                          <v-col sm="10">
                            <v-select
                            v-model="getEditedResponse.teacher_id"
                            :items="getTeachers"
                            item-text="name"
                            item-value="id"
                            :menu-props="{ maxHeight: '400' }"
                            label="Select Teacher"
                            chips
                            solo
                            ></v-select>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col sm="2" class="font-weight-bold">
                            Comments
                          </v-col>
                          <v-col sm="10">
                            <v-textarea
                            v-model="getEditedResponse.comments"
                            name="input-7-1"
                            label="Comments"
                            value=""
                            hint="A description of the student speaking"
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-card-actions class="d-flex justify-center">
                      <v-btn class="bbe-blue" dark text @click="save">
                        Save
                      </v-btn>
                      <v-btn class="bbe-orange" dark text @click="close">
                        Cancel
                      </v-btn>
                    </v-card-actions>
                  </v-card-text>
                  </v-main>
              </v-card>
            </v-dialog>
            <!-- The following dialog is for delete item -->
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline justify-center bbe-blue white--text" >
                  Response - Delete
                </v-card-title>
                <div class="text--primary text-center ma-2">
                  Are you sure you want to delete this item?
                </div>
                <v-card-actions class="justify-center">

                  <v-btn class="bbe-orange" dark text @click="deleteItemConfirm">
                    DELETE
                  </v-btn>
                  <v-btn class="bbe-blue" dark text @click="closeDelete">
                    CANCEL
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- the following dialog is for show -->
            <v-dialog v-model="dialogShow" >
              <v-card>
                <v-card-title class="headline justify-center bbe-blue white--text" >
                  Response - Show
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="2" class="font-weight-bold">
                        Name
                      </v-col>
                      <v-col cols="12" sm="4">
                        {{ editedItem | setStudentName }}
                      </v-col>

                      <v-col cols="12" sm="2" class="font-weight-bold">
                        Language
                      </v-col>
                      <v-col cols="12" sm="4">
                        {{ editedItem | setLanguageName }}
                      </v-col>
                    </v-row>


                    <v-row>
                      <v-col cols="12" sm="2" class="font-weight-bold">
                        Speaking Exam Level
                      </v-col>
                      <v-col cols="12" sm="4">
                        {{ editedItem.level }}
                      </v-col>
                      <v-col cols="12" sm="2" class="font-weight-bold">
                        Written Exam Level
                      </v-col>
                      <v-col cols="12" sm="4">
                        {{ editedItem.writing_level }}
                      </v-col>

                      <v-col cols="12" sm="2" class="font-weight-bold">
                        General Level
                      </v-col>
                      <v-col cols="12" sm="4">
                        {{ editedItem.general_level }}
                      </v-col>
                      
                      <v-col cols="12" sm="2" class="font-weight-bold"  v-if="getIsUserAdmin">
                        Email
                      </v-col>
                      <v-col cols="12" sm="4"  v-if="getIsUserAdmin">
                        {{ editedItem | setStudentEmail }}
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="2" sm="2" class="font-weight-bold">
                        Teacher
                      </v-col>
                      <v-col cols="4" sm="4" class="align-center">
                        {{ editedItem | setTeacherName }}
                      </v-col>

                      <v-col cols="2" sm="2" class="font-weight-bold">
                        Company
                      </v-col>
                      <v-col cols="4" sm="4" class="align-center">
                        {{ editedItem | setCompanyName }}
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" sm="2" class="font-weight-bold">
                        Comments
                      </v-col>
                      <v-col cols="12" sm="10">
                        {{ editedItem.comments }}
                      </v-col>
                    </v-row>


                  </v-container>
                </v-card-text>
              </v-card>
            </v-dialog>
          </template>

          <template v-slot:item.graded_at="{ item }">
            {{ item | setGradedAt }}
          </template>

          <template v-slot:item.date_student="{ item }">
            {{ item | setAnsweredAt }}
          </template>
          <!-- The following are the icons for the CRUD actions -->
          <template v-slot:item.actions="{ item }" class="justify-around ">
            <div class="td-actions" style="min-width:120px">
              <v-icon medium @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon medium @click="showItem(item)">
                mdi-eye
              </v-icon>
              <v-icon medium @click="deleteItem(item)" v-if="!getIsUserTeacher">
                mdi-delete
              </v-icon>
            </div>
          </template>
        </v-data-table>
        <v-pagination
            v-model="page"
            :length="pageCount"
            @input="updatePagination"
            total-visible="8"
            ></v-pagination>
      </v-card>

    </v-card>
  </div>
</template>



<script>

import { mapGetters, mapActions } from "vuex";
import Errors from "@/components/Errors";
import Wavesurfer from "@/components/Wavesurfer"
import Treeview from "@/components/TreeView"
import ResponseFilter from "@/components/responses/ResponseFilter"
import Results from "@/components/Results";
import Loading from "@/components/Loading";
import JsonExcel from "vue-json-excel";

export default {
  created() {
    this.initializeResponse(this.filterParams);
    this.loadDropdownLevels();
    this.loadCompaniesDropdown();
    this.loadLanguagesDropdown();
    this.loadTeachers();
  },
  components: {
    Errors,
    Loading,
    ResponseFilter,
    Results,
    Treeview,
    Wavesurfer,
    JsonExcel
  },
  computed: {
    ...mapGetters([
      "getDropdownLevels",
      "getErrors",
      "getEditedResponse",
      "getDefaultResponse",
      "isInfoLoading",
      "getResponses",
      "getResults",
      "getQuestionSelected",
      "getQuestionSelectedLevel",
      "getQuestionSelectedTitle",
      "getIsUserTeacher",
      "getIsUserAdmin",
      "getTeachers",
      "getWaveSurfer",
      "getshowFilterActive",
      "getTotalResponsesPagination",
      "getFilterParams"
    ]),
    formTitle() {
      return this.getEditedResponse === -1 ? "New Response" : "Edit Response";
    },

    paginatedData() {
      console.log('filtros',this.getFilterParams);
      console.log("responses en el front",this.getResponses);
      if (!Array.isArray(this.getResponses)) {
        return [];
      }
      const start = (this.page - 1) * this.itemsPerPage;
      const end = this.page * this.itemsPerPage;
      return this.getResponses.slice(start, end);
    },
    pageCount() {
      return Math.ceil(this.getTotalResponsesPagination / this.itemsPerPage); // Assuming `total` is a field in your backend response
    },
  },
  data(){
    return {
      page: 1,
      itemsPerPage: 10,
      dialogEdit: false,
      dialogDelete: false,
      dialogShow: false,
      editedIndex: -1,
      editedItem: {
        id: "",
        student_id: "",
        student: "",
        language_id: "",
        language: "",
        graded: "",
        level: "",
        writing_level:"",
        general_level: "",
        teacher_id: "",
        teacher: "",
        comments: "",
        sent: "",
      },
      headers: [
        { text: "Student", value: "student.name" },
        { text: "Company", value: "student.company.name" },
        { text: "Language", value: "language.name" },
        { text: "Answered At", value: "date_student" },
        { text: "Teacher", value: "teacher.name" },
        { text: "Graded At", value: "graded_at" },
        { text: "Actions", value: "actions", sortable: false,class:'td-actions' }
      ],
      search: "",

      items: [
        {
          path: '/unassignedRespones',
          title: 'Assign Teacher'
        },
        {
          path: '/unsentResponses',
          title: 'Unsent Responses'
        },
      ],

      json_fields: {
      "Complete name":"student.name",
      "Email":"student.email",
      "Company" : "student.company.name",
      "Language":"language.name",
      "Date of presentation":"created_at",
      "Qualification date":"graded_at",
      "Teacher":{
        field: "teacher_id",
        callback:(value) =>{
          if(value != null) {
           let teachers = this.getTeachers
            for(let i = 0; i < teachers.length; i++){
                if( value === teachers[i].id){
                  return teachers[i].name
                }
              }
          } else {
            return " "
          }
        }
      },
      "Speaking Exam Level":"level",
      "Written Exam Level":"writing_level",
      "General Level": "general_level"
      },

      json_meta: [
        [
          {
            key: "charset",
            value: "UTF-8",
          },
        ],
      ],

      filterParams: {
        page: 0,
        page_count: 0
      },
      preparedData: [], 
      totalResponses: 0 // total de respuestas
    }
  },
  filters: {
    setLanguageName: function(item) {
      if (item.language === undefined || item.language === null) {
        return ""
      } else {
        return item.language.name
      }
    },
    setStudentName: function(item){
      if (item.student === undefined || item.student === null) {
        return ""
      } else {
        return item.student.name
      }
    },
    setStudentEmail: function(item){
      if (item.student === undefined || item.student === null) {
        return ""
      } else {
        return item.student.email
      }
    },
    setTeacherName: function(item) {
      if (item.teacher === undefined || item.teacher === null) {
        return ""
      } else {
        return item.teacher.name
      }
    },
    setCompanyName: function(item) {
      if (item.student.company === undefined) {
        return "Not Company Found"
      } else {
        return item.student.company.name
      }
    },
    setGradedAt: function(item) {
      if (item.graded_at === null) {
        return ""
      } else {
        let newDate = item.graded_at.split("T");
        return newDate[0]
      }
    },
    setAnsweredAt: function(item) {
      // let newDate = item.created_at.split("T")
      // return newDate[0]
      let utcDate
      if(item.date_student == null){
        utcDate = item.created_at
      }else{
        var dateUTC = new Date(item.date_student);
        utcDate = dateUTC.toISOString()
      }
        const date = new Date(utcDate);
        return date.toLocaleString();
    },

  },
  methods: {
    ...mapActions([
      "createResponse",
      "deleteResponse",
      "initializeResponse",
      "loadCompaniesDropdown",
      "loadDropdownLevels",
      "loadLanguagesDropdown",
      "loadTeachers",
      "playPauseAudio",
      "setEditedResponse",
      "setElemenToDelete",
      "stopsAudio",
      "updateResponse",
      "updateResponseWhitoutGrade",
      "setShowFilterActive",
      "changueOptions",
      "loadResponses",
      "loadResponsePagination",
      "updateFilterParams"
    ]),
    showFilter(){
      this.setShowFilterActive()
    },
    close() {
      this.$store.commit("INITIALIZE_ERRORS")
      this.$store.commit("INITIALIZE_RESULTS")
      this.stopsAudio();
      this.dialogEdit = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
     this.changueOptions();
    },
    closeDelete() {
      this.$store.commit("INITIALIZE_ERRORS")
      this.$store.commit("INITIALIZE_RESULTS")
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.setElemenToDelete(this.getDefaultResponse);
        this.editedIndex = -1;
      });
    },
    deleteItem(item) {
      this.setElemenToDelete(item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.deleteResponse(this.getEditedResponse);
      this.closeDelete();
    },
    editItem(item) {
      this.$store.commit("INITIALIZE_ERRORS")
      this.$store.commit("INITIALIZE_RESULTS")
      this.editedIndex = this.getResponses.indexOf(item);
      this.setEditedResponse(item)
      // this.getEditedResponse = Object.assign({}, item);
      this.dialogEdit = true;
    },
    save() {
      // Si general_level es null o undefined, se actualiza la respuesta
      if (!this.getEditedResponse.general_level) {
        this.updateResponseWhitoutGrade(this.getEditedResponse);
      } else if (this.editedIndex > -1) {
        // Si editedIndex es mayor que -1, significa que el registro fue modificado
        this.updateResponse(this.getEditedResponse);
      } else {
        // Si no se cumple ninguna de las condiciones anteriores, es una nueva respuesta
        this.createResponse(this.getEditedResponse);
      }

      this.close();
    },
    showItem(item) {
      this.showedIndex = this.responses.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogShow = true;
    },
    async updatePagination() {
      if (this.page < 1) this.page = 1;
      if (this.page > this.pageCount) this.page = this.pageCount;
      this.filterParams = {
        page: this.page,
        per_page: 10
      };
      await this.updateFilterParams(this.filterParams)
      await this.loadResponsePagination(this.getFilterParams);
    },
    
    async prepareData() {
      
      this.filterParams = {
        page: 0,
        per_page: 10
      };

      const combinedParams = {
        ...this.getFilterParams,
        ...this.filterParams
      };
      
      this.loadResponsePagination(combinedParams)
      .then(() => {
      this.preparedData = this.getResponses;
      this.$nextTick(() => {
        if (this.$refs.jsonExcel) {
          this.$refs.jsonExcel.generate();
        } else {
          console.error('jsonExcel ref not found');
        }
      });
    })
    .catch(error => {
      console.error('Error loading data:', error);
    });
    }
  },
  name: "Response",
  props: {
    responses: {
      type: Array,
      required: true
    }
  }
}

</script>
<style scoped>
  .td-actions{
    min-width: 120px;
  }

  .custom-scroll {
  scrollbar-width: thin; /* Para Firefox */
  scrollbar-color: #021a42 transparent; /* Para Firefox */
}

/* Para navegadores basados en WebKit */
.custom-scroll::-webkit-scrollbar {
  width: 12px; /* Ajusta el grosor de la barra de scroll */
  height: 12px; /* Ajusta el grosor de la barra de scroll horizontal */
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #021a42; /* Color de la barra de scroll */
  border-radius: 6px; /* Bordes redondeados */
  border: 3px solid transparent; /* Añadir un borde transparente para mostrar mejor el borde redondeado */
  background-clip: content-box; /* Hacer que el background-color no incluya el borde */
}

.custom-scroll::-webkit-scrollbar-track {
  background-color: transparent; /* Color de fondo del track */
  border-radius: 6px; /* Bordes redondeados del track */
}
</style>